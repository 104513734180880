// Modal.tsx
import React, { FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import './style.scss'

type ModalProps = {
  isOpen: boolean
  borderColor?: boolean
  onClose: () => void
  children: ReactNode
  styleContainer?: React.CSSProperties
  styleContainerContent?: React.CSSProperties
  style_Subcontainer?: React.CSSProperties
  styleClose?: React.CSSProperties
  overlayStyle?: React.CSSProperties
  typeborder?: 'borderAnimateIpass' | 'borderAnimateIpassError' | 'borderAnimateIpassDisable'
}

const Modal: FC<ModalProps> = ({
  isOpen,
  borderColor = false,
  styleContainerContent,
  onClose,
  children,
  styleContainer,
  styleClose,
  style_Subcontainer,
  overlayStyle,
  typeborder = 'borderAnimateIpass'
}) => {
  return isOpen
    ? createPortal(
        <div style={overlayStyle} className={`modal-overlay`}>
          <div style={styleContainer} className={`modal `}>
            <div
              style={styleContainerContent}
              className={`content ${borderColor ? `${typeborder} borderModal` : ''}`}
            >
              <div
                className={`subContent  ${borderColor ? 'inner' : ''}`}
                style={style_Subcontainer}
              >
                {children}
              </div>
            </div>
            <button className="close-button" onClick={onClose} style={styleClose}>
              X
            </button>
          </div>
        </div>,
        document.body
      )
    : null
}

export default Modal
