import React, { ReactNode } from 'react'
import './styles.scss'

interface LayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  personClassName?: string
  children: ReactNode
  status?: 'Normal' | 'Disable' | 'Error' | 'NO_BORDER'
  margin?: string | number // Permite definir márgenes como px, %, rem, etc.
  styleInner?: React.CSSProperties
}

export const BorderIpass: React.FC<LayoutProps> = ({
  children,
  styleInner,
  status = 'Normal',
  margin = 1,
  personClassName = '',
  ...props // Captura todos los demás props
}) => {
  const classStatus =
    status === 'Disable'
      ? 'borderAnimateIpassDisable'
      : status === 'Error'
      ? 'borderAnimateIpassError'
      : 'borderAnimateIpass'

  return (
    <div className={`${classStatus} border ${personClassName}`} {...props}>
      <div className="inner" style={{ margin: margin, ...styleInner }}>
        {children}
      </div>
    </div>
  )
}
