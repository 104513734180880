import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../Provider/AuthProvider'
import ModalCheckInCenter from '../../components/CheckIn/CheckInCenter'
import io from 'socket.io-client'
import { entries } from '../../types/entries'
import { listBrandsAll } from '../../services/brand'
import { startCamerasLocaFile, listcamerasFile } from '../../services/cameras'
import { brand } from '../../types/brand'
import { AccessConfig } from '../../types/AccessConfig'
import './style.scss'
import SocketNotifications from '../../components/CheckIn/SocketNotifications/whats'
import SocketReadQr from '../../components/CheckIn/SocketNotifications/readQr'
import TableCheckIn from './table'
import { IconButton } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { Select_Option_Resident } from '../../types/Message_center'
import { ListDataResident } from '../../services/entries'
const CheckFiles = () => {
  const { user, token, SocketBackEnd } = useAuth()
  const [brands, setBrands] = useState<brand[]>([])
  const [clientCameras, setclientCameras] = useState<AccessConfig[]>([])
  const [newEntrySocket, setNewEntrySocket] = useState<entries | undefined>()
  const [slider, setSlider] = useState(true)
  const [allResidents, setAllResidents] = useState<Select_Option_Resident[]>([])

  useEffect(() => {
    if (brands.length === 0) {
      getData()
    }

    let socketFiles: any

    if (clientCameras.length > 0) {
      // Conectar el cliente a la URL del servidor
      socketFiles = io('http://localhost:8085')

      // Cuando se conecta, emitir una acción al servidor
      socketFiles.on('connect', () => {
        console.log('Conectado al servidor local')
        startCamerasLocaFile(clientCameras, token)
      })
    }

    // Limpiar el socket cuando se desmonte el componente
    return () => {
      if (socketFiles) {
        socketFiles.disconnect()
      }
    }
  }, [clientCameras, token])

  useEffect(() => {
    const eventName = `payment-${user?.clientId}`
    const eventAction = async () => {
      const residents = await ListDataResident({})
      setAllResidents(residents)
    }

    if (SocketBackEnd) SocketBackEnd.on(eventName, eventAction)

    return () => {
      SocketBackEnd && SocketBackEnd.off(eventName, eventAction)
    }
  }, [SocketBackEnd])

  if (!user) {
    return <Navigate to="/login" />
  }

  const getData = async () => {
    try {
      if (!user.clientId) return

      const [brands, clientCameras, residents] = await Promise.all([
        listBrandsAll(token),
        listcamerasFile(user.clientId),
        ListDataResident({})
      ])

      setBrands(brands)
      setclientCameras(clientCameras)
      setAllResidents(residents)
    } catch (error) {
      console.error(error)
    }
  }
  return (
    <div className="homeContainer">
      <div className="containerTitle">
        <h4>Check-in Central {user.client?.name || ''}</h4>
      </div>
      {brands.length > 0 &&
        clientCameras.length > 0 &&
        clientCameras.map((camera, index) => (
          <div key={index} className={`divCamera ${camera.name}`}>
            <ModalCheckInCenter
              newEntrySocket={newEntrySocket}
              brands={brands}
              camera={camera}
              allResidents={allResidents}
            />
          </div>
        ))}

      <div className="RegisterAcess">
        <div style={{ position: 'absolute', left: '0px' }}>
          <IconButton onClick={() => setSlider(!slider)}>
            {slider ? (
              <ExpandMore style={{ color: 'white' }} />
            ) : (
              <ExpandLess style={{ color: 'white' }} />
            )}
          </IconButton>
        </div>
        <h3>Registro</h3>
        <div
          className="tabletcameracontainer"
          style={{
            maxHeight: slider ? '100%' : '0%'
          }}
        >
          <TableCheckIn handleOpenModal={() => {}} />
        </div>
      </div>

      {
        <SocketNotifications
          userId={user?.clientId}
          setNewEntrySocket={setNewEntrySocket}
          brands={brands}
        />
      }
      {<SocketReadQr userId={user?.clientId} setNewEntrySocket={setNewEntrySocket} />}
    </div>
  )
}

export default CheckFiles
