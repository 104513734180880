import React, { useState, useEffect } from 'react'
import { BorderIpass } from '../../BorderIpass/Index'

interface LayoutProps extends React.HTMLAttributes<HTMLInputElement> {
  value: string
  prefix?: string
  SelectStyles?: React.CSSProperties
  setAmount: (value: string) => void
  onEnter?: () => void // Función opcional a ejecutar al presionar Enter
}

export const InputNumber: React.FC<LayoutProps> = ({
  value,
  SelectStyles,
  setAmount,
  onEnter,
  prefix,
  ...props
}) => {
  // Estado interno para almacenar el valor mientras el usuario escribe
  const [internalValue, setInternalValue] = useState(value)

  useEffect(() => {
    setInternalValue(value)
  }, [value])

  // Maneja el cambio en el input sin formatear inmediatamente
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value
    // Permite solo dígitos y un punto decimal
    if (/^\d*\.?\d*$/.test(inputValue)) {
      setInternalValue(inputValue)
    }
  }

  // Al perder el foco, se formatea el número a 2 decimales
  const handleBlur = () => {
    if (internalValue === '') {
      setInternalValue('1.00')
      setAmount('1.00')
      return
    }

    const numericValue = parseFloat(internalValue)
    if (isNaN(numericValue)) {
      setInternalValue('1.00')
      setAmount('1.00')
    } else {
      const formattedValue = numericValue.toFixed(2)
      setInternalValue(formattedValue)
      setAmount(formattedValue)
    }
  }

  // Función para incrementar el valor
  const handleIncrement = () => {
    const numericValue = parseFloat(internalValue)
    const newValue = isNaN(numericValue) ? 1 : Math.max(1, numericValue + 1)
    const formattedValue = newValue.toFixed(2)
    setInternalValue(formattedValue)
    setAmount(formattedValue)
  }

  // Función para decrementar el valor
  const handleDecrement = () => {
    const numericValue = parseFloat(internalValue)
    const newValue = isNaN(numericValue) ? 1 : Math.max(1, numericValue - 1)
    const formattedValue = newValue.toFixed(2)
    setInternalValue(formattedValue)
    setAmount(formattedValue)
  }

  // Maneja los eventos de teclado:
  // - ArrowUp o "+" para incrementar.
  // - ArrowDown o "-" para decrementar.
  // - Enter para ejecutar la función onEnter (si se define).
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowUp' || e.key === '+') {
      e.preventDefault()
      handleIncrement()
    } else if (e.key === 'ArrowDown' || e.key === '-') {
      e.preventDefault()
      handleDecrement()
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (onEnter) onEnter()
    }
  }

  return (
    <BorderIpass margin={1} status="Normal" styleInner={SelectStyles}>
      <div className="custom-number-input">
        <input
          {...props}
          value={internalValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          type="text" // Se usa tipo texto para mantener control total del formateo
        />
        {prefix && <span>{prefix}</span>}
        <div className="controls">
          <button onClick={handleIncrement}>▲</button>
          <button onClick={handleDecrement}>▼</button>
        </div>
      </div>
    </BorderIpass>
  )
}
