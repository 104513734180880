import React, { useEffect, useState } from 'react'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { Fade, IconButton } from '@mui/material'
import { useLoading } from '../../Provider/LoadingProvider'

import DateRangePicker from '../../components/Form/RangePicker3'
import { Search } from '@mui/icons-material'
import { useDebounce } from '../../hooks/useDebounce'
import Modal from '../../components/GeneralComponents/Modal'
import { ModalCreateMenssage } from './ModalCreateMenssage'
import { Select_Option_Resident, typeMessage, typesearch } from '../../types/Message_center'
import moment from 'moment'
import { LightTooltip } from '../../components/tooltips/text'
import { get_all_Residents, get_all_SMG } from '../../services/Message'
import { get_whatsapp } from '../../services/whatsapp'

const MessageCenter = () => {
  const { user, token, SocketBackEnd } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [allResidents, setAllResidents] = useState<Select_Option_Resident[]>([])

  const [allMessage, setAllMessage] = useState<typeMessage[]>([])
  const [selected, setSelected] = useState<typeMessage>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [statusWhatsapp, setStatusWhatsapp] = useState(false)
  const [search, setSearch] = useState<typesearch>()
  const debounceValue = useDebounce(search, 500)

  useEffect(() => {
    if (user?.clientId) {
      getGeneralData()
      getStatusBot()
    }

    getResidentes()
  }, [debounceValue])

  useEffect(() => {
    if (selected) {
      setIsModalOpen(true)
    }
  }, [selected])

  useEffect(() => {
    const eventName = `payment-${user?.clientId}`
    const eventAction = async () => {
      const residents = await get_all_Residents({})
      setAllResidents(residents)
    }

    if (SocketBackEnd) SocketBackEnd.on(eventName, eventAction)

    return () => {
      SocketBackEnd && SocketBackEnd.off(eventName, eventAction)
    }
  }, [SocketBackEnd])

  const getStatusBot = async () => {
    try {
      if (!user || !user.clientId) return

      const { status } = await get_whatsapp(user.clientId, token)
      const match = status.match(/activo con (.+)/i)

      if (match) {
        setStatusWhatsapp(true)
      } else {
        setStatusWhatsapp(false)
      }
    } catch (error) {
      console.error('error al consultar estado Bot')
      setStatusWhatsapp(false)
    }
  }
  const getGeneralData = async () => {
    try {
      startLoading()
      const data = await get_all_SMG(search)
      setAllMessage(data)
    } catch (error) {
    } finally {
      stopLoading()
    }
  }

  const handleChange = <K extends keyof typesearch>(params: {
    name: K
    value: typesearch[K]
  }) => {
    setSearch(prevSearch =>
      prevSearch
        ? {
            ...prevSearch,
            [params.name]: params.value
          }
        : { [params.name]: params.value }
    )
  }

  const closeModal = () => {
    setIsModalOpen(false)
    setSelected(undefined)
    getGeneralData()
  }

  const selectType: {
    [key: string]: string
  } = {
    Express: 'TICKET EXPRESS',
    select: 'SELECTIVO',
    All: 'COLECTIVO'
  }

  const getResidentes = async (destines?: string[]) => {
    const data = await get_all_Residents({ search: destines })
    setAllResidents(data)
  }

  if (!allMessage || !user?.clientId)
    return (
      <div className="AccreditationsContainer">
        <div className="containerTitle">
          <h4>CENTRAL DE MENSAJES IPASS</h4>
        </div>
      </div>
    )

  return (
    <div className="AccreditationsContainer">
      <div className="containerTitle">
        <h4>CENTRAL DE MENSAJES IPASS</h4>
      </div>
      <div className="Message_center-search-section ">
        <div
          className="dashboard-search-bar-container-dashboard"
          style={{ height: '40px', marginBottom: '0px' }}
        >
          <div className="dashboard-search-icon">
            <Search style={{ color: 'white' }} />
          </div>
          <input
            name="Buscar mensaje"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ name: 'text', value: e.target.value })
            }
            type="text"
            className="dashboard-search-input"
            placeholder="Buscar mensaje"
          />
        </div>
        <DateRangePicker
          label1={'Desde'}
          label2={'Hasta'}
          onChange={value => handleChange({ name: 'date', value })}
        />

        <h3 className="Message_center-barStatus">MENSAJES</h3>
        <LightTooltip
          title={`${
            statusWhatsapp ? 'Crear nuevos mensajes' : 'Inicie seccion whatsapp-Ipass'
          }`}
          followCursor
          TransitionComponent={Fade}
        >
          <button
            className="circular-button"
            onClick={() => setIsModalOpen(true)}
            /*             disabled={!statusWhatsapp}
             */
          >
            <span className="plus-icon">+</span>
          </button>
        </LightTooltip>
      </div>
      {allMessage && (
        <div>
          <div className="Message_center-container">
            <div className="order header">
              <span>FECHA</span>
              <span>TITULO</span>
              <span>TIPO</span>
              <span>DESTINO(S)</span>
              <span>MENSAJE</span>
              <span>ADJUNTO</span>
              <span>ACCION</span>
            </div>

            {allMessage.map((msg, index) => (
              <div key={index} className="order Payments">
                <span>
                  {msg.createdAt ? moment(msg.createdAt).format('DD/MM/YY h:mm a') : ''}
                </span>
                <span>{msg.title}</span>
                <span>{msg.type ? selectType[msg.type] : 'Deconocido'}</span>
                <span>{`${msg.SendDestines || msg.destines.length || 0} - ${
                  msg.OkSendDestines || 0
                }`}</span>
                <LightTooltip title={`${msg.text}`} followCursor TransitionComponent={Fade}>
                  <span>
                    {msg.text.length > 25 ? msg.text.slice(0, 25) + '...' : msg.text}
                  </span>
                </LightTooltip>
                <span>{msg.nameFile}</span>

                <IconButton
                  onClick={() => {
                    setSelected(msg)
                    setIsModalOpen(true)
                  }}
                >
                  <VisibilityIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
      <Modal
        borderColor={true}
        isOpen={isModalOpen}
        onClose={closeModal}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '60%',
          height: '80%',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <ModalCreateMenssage
          selected={selected}
          onClose={closeModal}
          allResidents={allResidents}
        />
      </Modal>
    </div>
  )
}

export default MessageCenter
