import axios, { AxiosRequestHeaders, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import Cookies from 'js-cookie'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BACKEND,
  timeout: 1000 * 60 * 5,
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    // Verificar si estamos en Electron
    const isElectron = (window as any).ipcRenderer !== undefined

    if (isElectron) {
      // Obtener el token usando ipcRenderer
      const ipcRenderer = (window as any).ipcRenderer
      const token = await new Promise<string>(resolve => {
        ipcRenderer.send('get-store-token') // Solicitar el token al proceso principal
        ipcRenderer.on('token-get', (_: any, retrievedToken: string) => {
          resolve(retrievedToken) // Resolver la promesa con el token
        })
      })

      // Añadir el token a las cabeceras si existe
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        } as AxiosRequestHeaders
      }
    } else {
      // Obtener el token desde Cookies (para aplicaciones web)
      const token = Cookies.get('token')
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`
        } as AxiosRequestHeaders
      }
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export default axiosInstance
