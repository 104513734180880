// registerRTSP.tsx
import React, { FC, useEffect, useState, useRef } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import { useLoading } from '../../../Provider/LoadingProvider'
import { createVisitor } from '../../../services/visitor'
import { printQRCode } from '../../../utils/printer'
import Button from '../../Form/Button'
import { entries, entriesApprovedEnum } from '../../../types/entries'
import NewCameraFiles from '../NewCameraFiles'
import { Toaster, toast } from 'sonner'
import { brand } from '../../../types/brand'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import { AccessConfig } from '../../../types/AccessConfig'
import Icon from '@ant-design/icons'
import { ReactComponent as waitInLobby } from '../../../assets/svg/waitInLobby.svg'
import { ReactComponent as leaveInLobby } from '../../../assets/svg/leaveInLobby.svg'
import { ReactComponent as noLeaveInLobby } from '../../../assets/svg/noLeaveInLobby.svg'
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  QrcodeOutlined,
  PhoneOutlined,
  LikeOutlined,
  DislikeOutlined,
  Loading3QuartersOutlined
} from '@ant-design/icons'
import './styles.scss'
import PlaceholderSvg from '../../PlaceholderSvg'
import { Fade } from '@mui/material'
import { LightTooltip } from '../../../components/tooltips/text'
import ContextMenu from '../ContextMenu'
import { typeQrEnumAbbreviationString } from '../../../types/qr'
import { Select_Option_Resident } from '../../../types/Message_center'
import { FormCheckInCenter } from './form'
import { LocalHospital } from '@mui/icons-material'

type ModalCheckInCenterProps = {
  newEntrySocket: entries | undefined
  brands: brand[]
  camera: AccessConfig
  allResidents: Select_Option_Resident[]
}

export type FormType = {
  brand?: string
  destinationCode?: string
  comment?: string
  camera: string
}

const ModalCheckInCenter: FC<ModalCheckInCenterProps> = ({
  newEntrySocket,
  brands,
  camera,
  allResidents
}) => {
  const [TimeImg, setTimeImg] = useState<NodeJS.Timeout | null>(null)
  const [lastEntry, setlastEntry] = useState<entries | null | undefined>()
  const [okEntry, setOkEntry] = useState<boolean>()
  const [isEmergency, setIsEmergency] = useState<boolean>(false)
  const [loadingCamera, setloadingCamera] = useState<boolean>(false)
  const preSelectBrand = localStorage.getItem(`${camera.name}SelectBrand2`)
  const [blinkDuration, setBlinkDuration] = useState(5)
  const [brandPhoto, setBrandPhoto] = useState<string | undefined>(undefined)
  const TimeClearqr: number = 5000

  const multiSelectRef = useRef<{ focus: () => void }>(null)

  const { user, token } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const [images, setImages] = useState<{
    photo: File | null
    DNI_A: File | null
    // DNI_B: File | null
  }>({
    photo: null,
    DNI_A: null
    // DNI_B: null
  })

  const [defaultImg, setDefaultImg] = useState<{
    photo: string
    DNI_A: string
  }>({
    photo: '/images/logo-ipass-clear.webp',
    DNI_A: '/images/logo-ipass-clear.webp'
  })

  const [formValues, setFormValues] = useState<FormType>({
    brand: preSelectBrand && preSelectBrand !== 'undefined' ? preSelectBrand : undefined,
    camera: camera.name
  })

  useEffect(() => {
    const newBrand = brands.find(e => e.id === lastEntry?.brandId)?.photo
    setBrandPhoto(newBrand)
  }, [brands, lastEntry])

  useEffect(() => {
    if (newEntrySocket) {
      if (!lastEntry || newEntrySocket.qrCode !== lastEntry.qrCode)
        if (
          !lastEntry ||
          newEntrySocket.destination !== lastEntry.destination ||
          newEntrySocket.cameraIn !== camera.name
        )
          return

      setlastEntry(newEntrySocket)
      setDefaultImg({
        photo: newEntrySocket?.photo ? newEntrySocket?.photo : '/images/logo-ipass-clear.webp',
        DNI_A: newEntrySocket?.DNI_A ? newEntrySocket?.DNI_A : '/images/logo-ipass-clear.webp'
      })

      const isClear = [
        'APPROVED',
        'REJECT',
        'RECESSION',
        'LEAVE',
        'NO_LEAVE',
        'UNANSWERED'
      ].includes(`${newEntrySocket?.approved}`)

      if (isClear) {
        if (newEntrySocket.qrCode) {
          clearAndFocusTime(TimeClearqr)
        } else {
          clearAndFocusTime(5000)
        }
      }
    }
  }, [newEntrySocket])

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null

    const minDuration = 0.1 // Mínima duración permitida
    const initialDuration = 5 // Duración inicial del parpadeo
    const totalEffectTime = 30000 // Tiempo total para llegar al mínimo (en milisegundos)
    const decrementInterval = 2100 // Frecuencia con la que disminuimos el tiempo (1 segundo)

    const startBlinkEffect = (currentDuration: number, remainingTime: number) => {
      if (remainingTime <= 0 || currentDuration <= minDuration) {
        setBlinkDuration(minDuration)
        setlastEntry(old => (old ? { ...old, approved: entriesApprovedEnum.CALLING } : null))
        return
      }
      if (timeout) clearTimeout(timeout)

      // Calcula el siguiente valor de la duración, reduciendo de forma proporcional
      const decrement = (initialDuration - minDuration) * (decrementInterval / totalEffectTime)
      const newDuration = currentDuration - decrement

      setBlinkDuration(newDuration >= minDuration ? newDuration : minDuration)

      // Espera a que la animación actual termine antes de volver a ajustar la duración
      timeout = setTimeout(() => {
        startBlinkEffect(newDuration, newDuration * 1000) // Ajusta el tiempo restante
      }, newDuration * 1000) // Espera exactamente lo que dura la animación actual antes de reducir más
    }

    if (lastEntry?.approved === 'WAITING') {
      // Inicia el efecto después de un retraso de 15 segundos
      timeout = setTimeout(() => {
        startBlinkEffect(initialDuration, totalEffectTime)
      }, 15000) // Espera 15 segundos antes de empezar el parpadeo
    } else if (
      lastEntry &&
      ['APPROVED', 'REJECT', 'RECESSION', 'LEAVE', 'NO_LEAVE', 'UNANSWERED'].includes(
        `${lastEntry?.approved}`
      )
    ) {
      clearAndFocusTime(15000)
    }

    return () => {
      if (timeout) clearTimeout(timeout) // Limpiar el timeout cuando el componente se desmonta
      setBlinkDuration(initialDuration) // Restaurar la duración inicial
    }
  }, [lastEntry])

  useEffect(() => {
    if (!images.photo && !images.DNI_A) return
    if (TimeImg) clearTimeout(TimeImg)

    if (lastEntry && lastEntry.approved !== 'WAITING') {
      allClean()
    }

    setTimeImg(
      setTimeout(() => {
        if (images.DNI_A || images.photo) return allClean()
      }, 30000)
    )

    return () => {
      console.log('resetTime')
      if (TimeImg) clearTimeout(TimeImg)
    }
  }, [images, formValues])

  const options = user?.canApprove
    ? [
        {
          label: 'Autorizar Ingreso',
          newStatus: 'APPROVED',
          icon: CheckCircleOutlined,
          colorSvg: 'green'
        },
        {
          label: 'No se espera visita',
          newStatus: 'REJECT',
          icon: CloseCircleOutlined,
          colorSvg: 'red'
        },
        {
          label: 'Espera en recepcion',
          newStatus: 'RECESSION',
          icon: waitInLobby
        },
        {
          label: 'Dejar paqueteria',
          newStatus: 'LEAVE',
          icon: leaveInLobby
        },
        {
          label: 'No espera paqueteria',
          newStatus: 'NO_LEAVE',
          icon: noLeaveInLobby
        },
        {
          label: 'VISITA DESATENDIDA',
          newStatus: 'UNANSWERED',
          icon: CloseCircleOutlined,
          colorSvg: 'red'
        }
      ]
    : [
        {
          label: 'VISITA DESATENDIDA',
          newStatus: 'UNANSWERED',
          icon: CloseCircleOutlined,
          colorSvg: 'red'
        }
      ]

  const allClean = () => {
    if (TimeImg) clearTimeout(TimeImg)

    const preSelectBrand = localStorage.getItem(`${camera.name}SelectBrand2`)
    setFormValues({
      brand: preSelectBrand && preSelectBrand !== 'undefined' ? preSelectBrand : undefined,
      camera: camera.name
    })
    setImages({
      photo: null,
      DNI_A: null
      // DNI_B: null
    })
    setDefaultImg({
      photo: '/images/logo-ipass-clear.webp',
      DNI_A: '/images/logo-ipass-clear.webp'
    })
    setlastEntry(null)
    setOkEntry(false)
  }

  const clearAndFocusTime = (Timer: number) => {
    setTimeout(() => {
      allClean()
      if (multiSelectRef.current) {
        multiSelectRef.current.focus()
      }
    }, Timer) //
  }

  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    WaitImgScam: (
      <Loading3QuartersOutlined
        style={{
          fontSize: '8rem',
          color: '#f600c0',
          animation: `rotate linear 1s infinite`
        }}
      />
    ),

    WaitQr: (
      <QrcodeOutlined
        style={{
          fontSize: '8rem',
          color: '#f600c0',
          animation: `rotate linear 2s infinite`
        }}
      />
    ),

    WAITING: (
      <ContextMenu options={options} lastEntry={lastEntry} setlastEntry={setlastEntry}>
        <LightTooltip
          title={
            lastEntry?.name || lastEntry?.lastName
              ? `${lastEntry?.name ? lastEntry.name : ''} ${
                  lastEntry?.lastName ? lastEntry.lastName : ''
                }`
              : undefined
          }
          TransitionComponent={Fade}
          disableHoverListener={lastEntry?.name ? false : true}
        >
          <div>
            <ClockCircleOutlined
              style={{
                color: 'yellow',
                animation: blinkDuration !== 5 ? `blink linear ${blinkDuration}s infinite` : ''
              }}
            />
          </div>
        </LightTooltip>
      </ContextMenu>
    ),
    CALLING: (
      <ContextMenu options={options} lastEntry={lastEntry} setlastEntry={setlastEntry}>
        <LightTooltip
          title={
            lastEntry?.name || lastEntry?.lastName
              ? `${lastEntry?.name ? lastEntry.name : ''} ${
                  lastEntry?.lastName ? lastEntry.lastName : ''
                }`
              : undefined
          }
          TransitionComponent={Fade}
        >
          <PhoneOutlined style={{ color: 'green', fontSize: '7rem' }} />
        </LightTooltip>
      </ContextMenu>
    ),
    APPROVED: <LikeOutlined style={{ color: 'green' }} />,
    REJECT: <DislikeOutlined style={{ color: 'red' }} />,
    RECESSION: <Icon component={waitInLobby} />,
    LEAVE: <Icon component={leaveInLobby} />,
    NO_LEAVE: <Icon component={noLeaveInLobby} />,
    UNANSWERED: <CloseCircleOutlined style={{ color: 'red' }} />
  }

  const handleCheckIn = async () => {
    try {
      if (TimeImg) clearTimeout(TimeImg)

      startLoading()
      const formData = new FormData()

      Object.entries(formValues).forEach(([key, value]) => {
        value && formData.append(key, value)
      })
      Object.entries(images).forEach(([key, value]) => {
        formData.append(key, value as Blob)
      })
      formData.append('clientId', user?.clientId as string)

      const getData = async () => {
        try {
          const visitor = await createVisitor(formData, isEmergency, token)
          if (visitor) {
            toast.success('El check-in se registró correctamente', {
              description: `${visitor.name} ${visitor.lastName}`,
              position: 'top-right'
            })
          } else {
            toast.success('El check-in se registró correctamente', {
              position: 'top-right'
            })
          }
          return visitor
        } catch (error: any) {
          if (error?.response?.data?.detalles) {
            toast.warning(`${error?.response?.data?.detalles[0].toUpperCase()}`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          } else if (error?.response?.status === 400) {
            toast.warning(`Falta información`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          } else {
            toast.error(`Error desconocido`, {
              position: 'top-right'
            })
            throw new Error(`${error?.response?.data?.msg}`)
          }
        }
      }

      // Llamar a la función al montar el componente
      const entry = await getData()
      setlastEntry(entry)
      setOkEntry(true)
      await printQRCode(entry.qrCode as string, entry, user?.client?.name || '')
    } catch (error) {
      console.error('API Error:', error)
    } finally {
      stopLoading()
    }
  }

  // Maneja el cambio de valores del formulario
  const handleInputChange = ({ name, value }: { name: string; value: string }) => {
    setFormValues({
      ...formValues,
      [name]: value
    })
  }

  const handleEmergencybutton = () => {
    if (isEmergency) {
      allClean()
    } else {
      setFormValues({
        ...formValues,
        brand: '0d0cdf7d-949c-4975-8573-7ce533c1f4fa',
        comment: 'Ingreso autorizado por medio de emergencia'
      })

      setDefaultImg({
        photo: '/images/IPASS-Emergencia.png',
        DNI_A: '/images/IPASS-Emergencia.png'
      })
    }

    setIsEmergency(!isEmergency)
  }

  const MyGradientArrow = () => {
    return (
      <svg className="my-icon" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" style={{ stopColor: '#f600c0', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#4409a4', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <DoubleArrowIcon className="gradient-fill" />
      </svg>
    )
  }

  const ModalLaterals: React.FC = () => {
    const type = lastEntry?.qrCode?.slice(0, 4) as keyof typeof typeQrEnumAbbreviationString
    const values = typeQrEnumAbbreviationString[type]

    const handleIconColor = (value: entriesApprovedEnum) =>
      ({
        [entriesApprovedEnum.APPROVED]: (
          <div className="green circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.CALLING]: (
          <div className="yellow circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.WAITING]: (
          <div className="yellow circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.REJECT]: (
          <div className="red circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.UNANSWERED]: (
          <div className="red circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.LEAVE]: (
          <div className="yellow circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.NO_LEAVE]: (
          <div className="red circle" style={{}}>
            <p>{values}</p>
          </div>
        ),
        [entriesApprovedEnum.RECESSION]: (
          <div className="yellow circle" style={{}}>
            <p>{values}</p>
          </div>
        )
      }[value])
    return (
      <>
        <div className="modal-laterals-header">
          <span className="textHeader">
            <strong>{lastEntry?.destination}</strong>
          </span>
          <span>INDICA:</span>
          <div className="stylesImgsvg">
            {statusSvg[lastEntry?.approved as entriesApprovedEnum]}
            {brandPhoto ? (
              <img src={brandPhoto} alt="foto de marca" />
            ) : lastEntry?.qrCode ? (
              handleIconColor(lastEntry?.approved as entriesApprovedEnum)
            ) : (
              <PlaceholderSvg />
            )}
          </div>
        </div>
        <div className="modal-laterals-foother">
          <strong>
            {lastEntry?.name ? lastEntry.name : ''}
            {' ' /* se deja espacio para separar nombres */}
            {lastEntry?.lastName ? lastEntry.lastName : ''}
          </strong>
        </div>
      </>
    )
  }

  return (
    <>
      <div className="containerModalCamera-CheckInCenter">
        <div>
          <h3>{camera.name}</h3>
          <div className="emergency_button">
            <LocalHospital
              style={{ color: 'red', fontSize: '2rem', cursor: 'pointer' }}
              onClick={handleEmergencybutton}
            />
          </div>
        </div>

        <div className="modal-sections-CheckInCenter">
          <div className="container-cameras-CheckInCenter">
            {
              <NewCameraFiles
                loading={setloadingCamera}
                onSubmitImage={value => setImages((old: any) => ({ ...old, photo: value }))}
                text="Foto del rostro"
                file={camera.FileFace}
                watch={images.photo}
                okEntry={okEntry}
                defaultImg={defaultImg.photo}
                setlastEntry={setlastEntry}
                nameCamera={camera.name}
                TimeClearqr={TimeClearqr}
              />
            }
            {
              <NewCameraFiles
                loading={setloadingCamera}
                onSubmitImage={value => setImages((old: any) => ({ ...old, DNI_A: value }))}
                text="DNI frontal"
                file={camera.FileDNI1}
                watch={images.DNI_A}
                okEntry={okEntry}
                defaultImg={defaultImg.DNI_A}
                setlastEntry={setlastEntry}
                nameCamera={camera.name}
                TimeClearqr={TimeClearqr}
              />
            }
          </div>
          <div className="container-Inputs-CheckInCenter">
            <FormCheckInCenter
              ref_focus={multiSelectRef}
              handleInputChange={handleInputChange}
              formValues={formValues}
              brands={brands}
              camera={camera}
              allResidents={allResidents}
            />
          </div>
          <div className="button-section-CheckInCenter">
            {(() => {
              const isCheckInButtonDisabled =
                !isEmergency && (!images.photo || !images.DNI_A || !formValues.destinationCode)

              const showCheckInButton = !lastEntry && !loadingCamera
              const showWaitImgScam = loadingCamera
              const showWaitQr = !loadingCamera && lastEntry?.qrCode && !lastEntry?.approved
              const showApprovedStatus =
                !loadingCamera && ['WAITING', 'CALLING'].includes(lastEntry?.approved!)
              const showModalLaterals =
                !loadingCamera &&
                [
                  'APPROVED',
                  'REJECT',
                  'RECESSION',
                  'LEAVE',
                  'NO_LEAVE',
                  'UNANSWERED'
                ].includes(`${lastEntry?.approved}`)

              if (showCheckInButton || isEmergency) {
                return (
                  <Button
                    disabled={isCheckInButtonDisabled}
                    style={isCheckInButtonDisabled ? { pointerEvents: 'none' } : {}}
                    onClick={handleCheckIn}
                    icon={isCheckInButtonDisabled ? <DoubleArrowIcon /> : <MyGradientArrow />}
                  />
                )
              } else if (showWaitImgScam) return statusSvg.WaitImgScam
              else if (showWaitQr) return statusSvg.WaitQr
              else if (showApprovedStatus) return statusSvg[lastEntry?.approved!]
              else if (showModalLaterals) return <ModalLaterals />
              return null
            })()}
          </div>
        </div>
      </div>

      <Toaster richColors />
    </>
  )
}

export default ModalCheckInCenter
