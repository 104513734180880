import React from 'react'
import './style.scss'
import { BorderIpass } from '../../BorderIpass/Index'

export interface ReportModalProps {
  onClose: () => void
}

export const WarningMora: React.FC<ReportModalProps> = ({ onClose }) => {
  return (
    <div className="miniWarnig">
      <>
        <h3>¡¡NO DISPONIBLE!!</h3>
        <p>{`Residente en estado de MORA, no se permiten aplicar más cargos hasta que esté AL DÍA.`}</p>
        <div className="Bottom">
          <BorderIpass margin={3} onClick={onClose}>
            SALIR
          </BorderIpass>
        </div>
      </>
    </div>
  )
}
