import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../Provider/AuthProvider'
import './style.scss'
import { roleEnum, roleEnumString } from '../../../types/user'
import { get_whatsapp } from '../../../services/whatsapp'
import { LightTooltip } from '../../tooltips/text'
import { Fade } from '@mui/material'
import { WhatsApp } from '@mui/icons-material'
import { Navigate } from 'react-router-dom'
import { TimeToken } from './TimeToken'
import Modal from '../../GeneralComponents/Modal'
import { BorderIpass } from '../../BorderIpass/Index'
import Icon from '@ant-design/icons'
import { ReactComponent as sadFace } from '../../../assets/svg/sadFace.svg'

const UserInfo = () => {
  const { user, token, SocketWhats } = useAuth()
  const [numberWhatsapp, setNumberWhatsapp] = useState<string>()
  const [isVisible, setIsVisible] = useState(true)
  const [isMora, setIsMora] = useState(
    localStorage.getItem(`modal_mora`) === '1' ? true : false
  )

  const mora_level = localStorage.getItem(`mora_level`)
  const code = user ? (user.role === 'super_admin' ? user.id : user.clientId) : undefined

  useEffect(() => {
    if (!user) return
    // Función para consultar el estado de la otra aplicación
    if (user.role === 'corporate') return

    // Llamar a la función al montar el componente
    getData()

    if (SocketWhats) {
      SocketWhats.on(`Status-bot-${code}`, () => {
        getData()
      })
    }
    // Limpia el evento cuando el componente se desmonta
  }, [user])

  useEffect(() => {
    if (!user) return

    const container = document.getElementById('main')! // Reemplaza con el ID de tu contenedor de scroll

    const handleScroll = () => {
      if (container.scrollTop > 25) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }

    // Añade el evento de scroll
    container.addEventListener('scroll', handleScroll)

    // Limpia el evento cuando el componente se desmonta
    return () => container.removeEventListener('scroll', handleScroll)
  }, [])

  const getData = async () => {
    try {
      if (!code) return
      const { status } = await get_whatsapp(code, token)
      const match = status.match(/activo con (.+)/i)

      if (match) {
        setNumberWhatsapp(match[1])
      } else {
        setNumberWhatsapp(undefined)
      }
    } catch (error) {
      console.error('error al consultar estado Bot')
      setNumberWhatsapp(undefined)
    }
  }
  if (!user) return <Navigate to="/login" />

  const closeModal = () => {
    localStorage.removeItem('modal_mora')
    setIsMora(false)
  }

  const msgClient: {
    [key: string]: { msg: React.JSX.Element; height: string }
  } = {
    '1': {
      msg: (
        <div className="modalMora">
          <h2>MENSAJE DEL EQUIPO IPASS</h2>
          <p>{`Hola ${user?.name
            .toUpperCase()
            .trim()}, estamos teniendo pequeños inconvenientes para asegurar la continuidad de tu cuenta en ${user?.client?.name
            .toUpperCase()
            .trim()} derivado que no hemos podido acreditar tu(s) pago(s), por favor permítenos saber cuando hayas actuado al respecto.`}</p>
          <p>Cuando lo consideres oportuno ponte en contacto con tu ejecutivo IPASS.</p>
          <div className="buttoom" onClick={closeModal}>
            <BorderIpass margin={3}>ENTENDIDO</BorderIpass>
          </div>
        </div>
      ),
      height: '250px'
    },
    '2': {
      msg: (
        <div className="modalMora">
          <h4>MENSAJE DEL EQUIPO IPASS</h4>
          <p>
            {`Hola ${user?.name
              .toUpperCase()
              .trim()}, los pequeños inconvenientes para asegurar la continuidad de tu cuenta en ${user?.client?.name
              .toUpperCase()
              .trim()} persisten, por lo que `}
            <strong>será suspendida en las próximos días</strong>.
          </p>
          <p>
            <strong>
              Es conveniente y crítico que asegures la normalización de tu(s) cuota(s) lo más
              pronto posible
            </strong>{' '}
            y te pongas en contacto con tu ejecutivo IPASS.
          </p>
          <p>
            De antemano lamentamos los inconvenientes si la suspensión llega a concretarse.
          </p>
          <div className="buttoom" onClick={closeModal}>
            <BorderIpass margin={3}>ENTENDIDO</BorderIpass>
          </div>
        </div>
      ),
      height: '350px'
    },
    '3': {
      msg: (
        <div className="modalMora">
          <div className="Title">
            <h4 className="red">SUSPENSIÓN DE LA CUENTA</h4>
            <Icon component={sadFace} className="icon" />
          </div>
          <p>
            {`Hola ${user?.name
              .toUpperCase()
              .trim()}, no pudimos verificar la normalización de tu(s) cuota(s) en ${user?.client?.name
              .toUpperCase()
              .trim()},`}{' '}
            <strong>Lamentamos tener que suspender tu cuenta</strong>, lo cual sucederá en las
            próximas horas.
          </p>
          <p>
            Por favor ponte en contacto con tu ejecutivo IPASS en cuanto hayas actuado al
            respecto.
          </p>
          <p>
            Sabemos de las consecuencias de ésta suspensión para ti y tus cientos de usuarios,
            queremos asegurarnos de tenerte de vuelta lo más pronto posible.
          </p>
          <div className="buttoom" onClick={closeModal}>
            <BorderIpass status={'Error'} margin={3}>
              ENTENDIDO
            </BorderIpass>
          </div>
        </div>
      ),
      height: '350px'
    }
  }

  return (
    <>
      <TimeToken />
      <div
        className={`${isVisible ? 'containerUserInfo visible' : ' containerUserInfo hidden'}`}
      >
        <div className="imageContainer">
          <img
            src={user.photo !== 'undefined' ? user.photo : `images/icons/user.png`}
            alt=""
          />
        </div>
        <div className="textContainer">
          <p>
            {roleEnumString[user.role as roleEnum].toLocaleUpperCase()}
            {': '}
            {user.name.toLocaleUpperCase()}
          </p>
          {user.client?.name && <p> {user.client.name.toUpperCase()}</p>}
          {user.role !== 'corporate' && (
            <LightTooltip
              title={numberWhatsapp}
              followCursor
              TransitionComponent={Fade}
              placement="right"
            >
              <div>
                <WhatsApp
                  style={{ color: numberWhatsapp ? 'green' : 'red', fontSize: '1.3rem' }}
                />
              </div>
            </LightTooltip>
          )}
        </div>
      </div>
      {isMora && mora_level && msgClient[mora_level!].msg && msgClient[mora_level!].height && (
        <Modal
          isOpen={isMora && mora_level ? true : false}
          borderColor={true}
          onClose={closeModal}
          styleContainerContent={{ padding: '5px', margin: '0px' }}
          styleContainer={{
            width: '520px',
            height: msgClient[mora_level!].height,
            padding: '0px',
            overflow: 'hidden',
            margin: '5px',
            borderRadius: '20px'
          }}
          overlayStyle={{
            ...(mora_level === '3' ? { backgroundColor: 'rgb(3 0 6 / 97%)' } : {})
          }}
          style_Subcontainer={{
            overflow: 'hidden',
            padding: '0rem 0.5rem',
            borderRadius: '15px'
          }}
          styleClose={{ display: 'none' }}
          typeborder={mora_level === '3' ? 'borderAnimateIpassError' : 'borderAnimateIpass'}
        >
          {msgClient[mora_level!].msg}
        </Modal>
      )}
    </>
  )
}

export default UserInfo
