import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './style.scss'
import { useAuth } from '../../Provider/AuthProvider'
import { listPaymentsResident } from '../../types/Payments'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IconButton } from '@mui/material'
import { getDasboardPayments } from '../../services/Payments'
import { useLoading } from '../../Provider/LoadingProvider'
import Modal from '../../components/GeneralComponents/Modal'

import PaymentsDetails from '../../components/Tables/PaymentsDetails'
import DateRangePicker from '../../components/Form/RangePicker3'
import { MultiSelectANTD } from '../../components/Form/multiSelet'
import {
  Cancel,
  CheckCircle,
  IndeterminateCheckBoxOutlined,
  Search,
  Update
} from '@mui/icons-material'
import { useDebounce } from '../../hooks/useDebounce'
import { ChartsAcreditamiento } from './Charts'

export interface typesearch {
  date?: string[]
  text?: string
  order?: string
  status?: string[]
}

const Accreditations = () => {
  const { user, SocketWhats, SocketBackEnd } = useAuth()
  const { startLoading, stopLoading } = useLoading()
  const { id } = useParams<{ id: string }>()
  const [idSearch, setIdSearch] = useState<string>()
  const [GeneralData, setGeneralData] = useState<listPaymentsResident[]>()
  const [selected, setSelected] = useState<{ data: listPaymentsResident; action: string }>()
  const [search, setSearch] = useState<typesearch>({ status: ['EN VERIFICACIÓN'] })
  const debounceValue = useDebounce(search, 500)

  useEffect(() => {
    if (debounceValue && idSearch) getGeneralData(idSearch)
  }, [debounceValue, idSearch])

  useEffect(() => {
    const eventName = `payment-${idSearch}`

    const eventAction = (message: string) => {
      getGeneralData(message, false)
    }

    if (SocketWhats) {
      SocketWhats.on(eventName, eventAction)
    }

    if (SocketBackEnd) {
      SocketBackEnd.on(eventName, eventAction)
    }

    return () => {
      SocketWhats && SocketWhats.off(eventName, eventAction)
    }
  }, [idSearch, SocketWhats, SocketBackEnd])

  useEffect(() => {
    const dataId = user?.role === 'corporate' ? (id as string) : user?.clientId
    setIdSearch(dataId)
  }, [id, idSearch, setIdSearch, user])

  const getGeneralData = async (id: string, Loading: boolean = true) => {
    try {
      Loading && startLoading()
      const data = await getDasboardPayments(id, debounceValue)
      setGeneralData(data)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const closeModal = () => {
    setSelected(undefined)
    if (idSearch) {
      getGeneralData(idSearch)
    }
  }

  const handleChange = <K extends keyof typesearch>(params: {
    name: K
    value: typesearch[K]
  }) => {
    setSearch(prevSearch =>
      prevSearch
        ? {
            ...prevSearch,
            [params.name]: params.value
          }
        : { [params.name]: params.value }
    )
  }

  const StatusOptions = [
    { value: 'AL DÍA', label: 'AL DÍA' },
    { value: 'EN VERIFICACIÓN', label: 'EN VERIFICACIÓN' },
    { value: 'EN MORA', label: 'EN MORA' }
  ]
  const statusSvg: {
    [key: string]: React.JSX.Element
  } = {
    'VERIFICAR FECHAS': <IndeterminateCheckBoxOutlined className="dashBoardInterminate" />,
    'AL DÍA': <CheckCircle className="dashBoardClose" />,
    'EN MORA': <Cancel className="dashBoardSpam" />,
    'EN VERIFICACIÓN': <Update className="dashBoardHold" />
  }

  return (
    <div className="AccreditationsContainer">
      <div className="containerTitle">
        <h4>Acreditamientos</h4>
      </div>

      {selected && (
        <>
          {selected.action === 'viewPayment' && (
            <Modal
              isOpen
              borderColor
              onClose={closeModal}
              styleContainerContent={{ padding: '5px', margin: '0px' }}
              styleContainer={{
                width: '90%',
                height: '90%',
                padding: '0px',
                overflow: 'hidden',
                margin: '5px',
                borderRadius: '20px'
              }}
              styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
            >
              <PaymentsDetails Predata={selected.data} onClose={closeModal} />
            </Modal>
          )}
        </>
      )}
      {idSearch && (
        <div className="accreditations-graf">
          <ChartsAcreditamiento id={idSearch} />
        </div>
      )}

      <div className="accreditations-search-section">
        <h3 className="accreditations-barStatus">ESTATUS</h3>
        <DateRangePicker
          style={{ maxWidth: '30%' }}
          label1={'Desde'}
          label2={'Hasta'}
          picker="month"
          format={'YYYY-MM'}
          onChange={value => handleChange({ name: 'date', value })}
        />

        <MultiSelectANTD
          placeholder="Estado"
          borderRadius={8}
          style={{ width: '30%' }}
          options={StatusOptions}
          value={search?.status}
          onChange={value => handleChange({ name: 'status', value })}
        />

        <div
          className="dashboard-search-bar-container-dashboard"
          style={{ height: '40px', marginBottom: '0px' }}
        >
          <div className="dashboard-search-icon">
            <Search style={{ color: 'white' }} />
          </div>
          <input
            name="Descripción..."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange({ name: 'text', value: e.target.value })
            }
            type="text"
            className="dashboard-search-input"
            placeholder="Destino / Residente"
          />
        </div>
      </div>

      {GeneralData && (
        <div>
          <div className="accreditations-container">
            <div className="order header">
              <span>FECHA</span>
              <span>DESTINO</span>
              <span>RESIDENTE</span>
              <span>SALDO</span>
              <span>FECHA PAGO</span>
              <span>ESTADO</span>
              <span>ACCION</span>
            </div>

            {GeneralData.map((report, index) => (
              <div key={index} className="order Payments">
                <span className="DestineAndStatus">
                  {statusSvg[report.status]}
                  <p>{report.month}</p>
                </span>
                <span>{report.destinationCode}</span>
                <span>{report.names}</span>
                <span>{`Q ${report.balance.toFixed(2)}`}</span>
                <span>{report.last_dateConfirm}</span>
                <span>{report.status}</span>

                <IconButton
                  onClick={() => {
                    setSelected({ data: report, action: 'viewPayment' })
                  }}
                >
                  <VisibilityIcon style={{ color: 'white' }} />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Accreditations
