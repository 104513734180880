import React, { FC, useState } from 'react'
import { useLoading } from '../../../Provider/LoadingProvider'
import { Image } from 'antd'
import { EyeOutlined } from '@ant-design/icons'

import './style.scss'
import { PaymentDetaild } from '../../../types/Payments'
import moment from 'moment'
import { BorderIpass } from '../../BorderIpass/Index'
import Modal from '../../GeneralComponents/Modal'
import { processPayment } from '../../../services/Payments'
import { InputNumber } from '../../Form/inputNumber'
import { MyTextArea } from '../../Form/labels/MyTextArea'

export const ModalEditPayment: FC<{
  IsEdit: boolean
  select: PaymentDetaild
  onClose: () => void
  closeAll: () => void
}> = ({ IsEdit, select, onClose, closeAll }) => {
  const { startLoading, stopLoading } = useLoading()
  const altPhoto = 'images/logo_inicio.png'
  const [isRejected, setIsRejected] = useState<boolean>(false)
  const [NotificationChange, setNotificationChange] = useState<boolean>(false)
  const [formState, setFormState] = useState<string>('')
  const [error, setError] = useState(false)
  const [amount, setAmount] = useState<string>((select.amount * -1).toFixed(2))

  const handleApproved = async () => {
    try {
      startLoading()
      await processPayment({
        amount: parseFloat(amount),
        paymentId: select.id,
        type: 'aprovado'
      })
      handleClosed(true)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleRejected = async () => {
    try {
      if (formState.length < 10) {
        setError(true)
        return
      }
      setError(false)
      startLoading()

      await processPayment({
        amount: parseFloat(amount),
        paymentId: select.id,
        type: 'rechazado',
        message: formState
      })

      handleClosed(true)
    } catch (error) {
      console.error(error)
    } finally {
      stopLoading()
    }
  }

  const handleClosed = (all: boolean) => {
    setError(false)
    setIsRejected(false)
    setNotificationChange(false)
    onClose()

    if (all) closeAll()
  }

  return (
    <div className="paymentsDetail-container">
      <>
        <div className="containerphoto">
          <div className="photo">
            <Image
              preview={{
                mask: (
                  <div>
                    <EyeOutlined />
                    <span>Ver</span>
                  </div>
                )
              }}
              wrapperStyle={{ width: '100%', height: '100%' }}
              style={{ width: '100%', height: '100%', objectFit: 'contain', margin: '0px' }}
              src={select.photo || altPhoto}
              className="image"
            />
          </div>
        </div>

        <div className="InfoModal">
          <div className="header">
            <p>{`${moment(select.createdAt).locale('es').format('DD/MM/YYYY h:mm:ss a')}`}</p>
            <p>Monto esperado: Q {select.expectedAmount.toFixed(2)}</p>
            <div className="Amounts">
              <p>Monto Acreditado : </p>
              <InputNumber value={amount} SelectStyles={SelectStyles} setAmount={setAmount} />
            </div>
          </div>
          <div className="buttom">
            <BorderIpass margin={4} onClick={() => setIsRejected(true)}>
              RECHAZAR
            </BorderIpass>

            <BorderIpass
              margin={4}
              onClick={() => {
                amount !== (select.amount * -1).toFixed(2)
                  ? setNotificationChange(true)
                  : handleApproved()
              }}
            >
              CONFIRMAR
            </BorderIpass>
          </div>
        </div>
      </>

      <Modal
        isOpen={isRejected}
        borderColor
        onClose={() => setIsRejected(false)}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '50%',
          height: '40%',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <div className="modalReject">
          <p>POR FAVOR DECRIBE EL MOTIVO DEL RECHAZO, ESTO SE LE ENVIARA AL RESIDENTE</p>

          <MyTextArea
            personClassName={'myTexarea'}
            labelError={'Se requiere minimo 10 caracteres'}
            error={error}
            colorBg={'#00000000'}
            placeholder="Rechazado por:"
            BorderSize={2}
            borderColor
            value={formState}
            onChange={value => setFormState(value)}
            style={{ resize: 'none', alignSelf: 'center' }}
            rows={2}
          />
          <div className="buttom">
            <BorderIpass margin={4} onClick={handleRejected}>
              RECHAZAR
            </BorderIpass>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={NotificationChange}
        borderColor
        onClose={() => setNotificationChange(false)}
        styleContainerContent={{ padding: '5px', margin: '0px' }}
        styleContainer={{
          width: '40%',
          height: '30%',
          padding: '0px',
          overflow: 'hidden',
          margin: '5px',
          borderRadius: '20px'
        }}
        styleClose={{ fontSize: '20px', right: ' 10px', top: '10px' }}
      >
        <div className="modalReject">
          <p>HAZ EDITADO EL MONTO</p>

          <div className="buttom">
            <BorderIpass margin={4} onClick={() => setNotificationChange(false)}>
              CANCELAR
            </BorderIpass>

            <BorderIpass margin={4} onClick={handleApproved}>
              OK
            </BorderIpass>
          </div>
        </div>
      </Modal>
    </div>
  )
}

const SelectStyles: React.CSSProperties = {
  margin: '1px',
  height: '30px',
  width: '110px',
  color: 'white',
  alignItems: 'center'
}
